<template>
  <div class="create-entrants-step">
    <CForm
      v-if="!isSelectTableVisible"
      ref="createEntrants"
      novalidate
      v-on:submit.prevent
    >
      <div class="form-content content">
        <!--  Header    -->
        <CRow class="p-0 m-0">
          <CCol col="12" class="d-flex p-0">
            <h3 class="mb-3 mr-1 bread-content">{{ texts.entrants.title }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.entrants"/>
          </CCol>
        </CRow>
        <!-- minNumberOfEntrants -->
        <CIInput
          v-if="type !== 'achievement'"
          type="number"
          min="0"
          :value="formData.minNumberOfEntrants"
          :label="texts.entrants.minNumberOfEntrantsLabel"
          :tooltip="descriptions.minNumberOfEntrants"
          v-model="formData.minNumberOfEntrants"
          :placeholder="texts.entrants.minNumberOfEntrantsPlaceholder"
          :required="true"
          name="minNumberOfEntrants"
          ref="minNumberOfEntrants"
          @blur="numberOfEntrantsBlur"
        />
        <!-- maxNumberOfEntrants -->
        <CIInput
          v-if="type !== 'achievement'"
          type="number"
          :min="this.formData.minNumberOfEntrants"
          :label="texts.entrants.maxNumberOfEntrantsLabel"
          :tooltip="descriptions.maxNumberOfEntrants"
          v-model="formData.maxNumberOfEntrants"
          :placeholder="texts.entrants.maxNumberOfEntrantsPlaceholder"
          :required="false"
          name="maxNumberOfEntrants"
          @blur="numberOfEntrantsBlur"
        />
        <!--  Opt-in required     -->
        <SwitchRow
          :label="texts.entrants.optLabel"
          :valueProp="optinRequiredForEntrants"
          @swipeHandler="swipeOptInHandler"
          @updateHandler="optInUpdate"
          :disabled="isFieldDisabled"
          :toolTipText="descriptions.optInRequired"
        />
        <!--  Limit Participants     -->
<!--        <SwitchRow
          :label="texts.entrants.limitLabel"
          :valueProp="limitParticipants"
          @swipeHandler="swipelimitParticipantsHandler"
          @updateHandler="limitParticipantsInUpdate"
          :disabled="isFieldDisabled"
          :toolTipText="descriptions.limitParticipants"
        />-->
        <!--    Limit Participants  Slider  -->
<!--        <ClFormRow v-if="limitParticipants" label="">
          <template #content>
            <RangeSlider
              :disabled="isFieldDisabled"
              :valueProp="[formData.minNumberOfEntrants, formData.maxNumberOfEntrants]"
              @update="handleUpdateLimit"
            />
          </template>
        </ClFormRow>-->
        <!--  Everyone can participate   -->
        <SwitchRow
          :label="texts.entrants.everyoneLabel"
          :valueProp="canParticipate"
          @swipeHandler="swipeCanParticipateHandler"
          @updateHandler="canParticipateUpdate"
          :disabled="isFieldDisabled"
          :toolTipText="descriptions.everyoneCanParticipate"
        />
        <!--   Restrict members to  -->
        <DepResultEntrantsTable
          v-if="!canParticipate"
          @toSelectTable="toSelectTable"
          @updateCurrentTypes="updateCurrentTypes"
          @updateData="handleUpdateEntrantsData"
          @resetValidation="resetEntrantsValidation"
          :currentTypesProps="currentTypes"
          :isValid="isValid"
          :propData="entrantsData"
          :disabled="isFieldDisabled"
          :toolTipText="descriptions.minimumShouldMatch"
        />
      </div>
    </CForm>
    <!--    ESelectTable    -->
    <DepSelectEntrantsTable
      v-if="isSelectTableVisible"
      @toResultTable="toResultTable"
      @updateSelectData="tagsSelectData"
      :fields="fields"
      storeType="tags"
      entityType="Member"
      :title="texts.entrants.selectTitle"
      :selectedData="selectedData"
      :formData="formData"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import SwitchRow from '@/shared/components/SwitchRow';
import DepSelectEntrantsTable from '@/shared/components/DepTables/DepSelectEntrantsTable';
import DepResultEntrantsTable from '@/shared/components/DepTables/DepResultEntrantsTable';
import {pageConfig} from "@/config";
import {isCompFieldDisabled} from "@/utils/validationUtils";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";
import {cloneDeep} from "lodash";

export default {
  name: 'CreateEntrants',
  components: {
    SwitchRow,
    IconWithTooltip,
    DepSelectEntrantsTable,
    DepResultEntrantsTable,
  },
  props: {
    entrantsData: Object,
    descriptions: Object,
    constraintsData: Array,
    isValid: Boolean,
    status: {
      type: String,
      default: 'Ready'
    },
    type: String,
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      isSelectTableVisible: false,
      optinRequiredForEntrants: false,
      limitParticipants: false,
      canParticipate: true,
      selectedData: [],
      selectedEntrantsData: {},
      currentTypes: {},
      // FOR QUERy
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      fields: [
        "select",
        "id",
        "created",
        "name",
        "key",
        "description",
      ],
      formData: {
        maxNumberOfEntrants: null,
        minNumberOfEntrants: null,
        shouldMatchAtLeast: 1,
        must: [],
        mustNot: [],
        should: []
      },
      constraints: [],
    }
  },
  computed: {
    isFieldDisabled() {
      return isCompFieldDisabled(this.status);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    //TODO: change this to 'handleGetTags' when API done
    ...mapActions('members', {
      handleGetTags: 'handleGetMembers'
    }),
    initialize() {
      this.handleGetTags({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      })
      this.formData = cloneDeep(this.entrantsData.formData);

      this.limitParticipants = this.entrantsData.limitParticipants;
      this.canParticipate = this.entrantsData.canParticipate;
      this.optinRequiredForEntrants = this.entrantsData.optinRequiredForEntrants;
      this.currentTypes = cloneDeep(this.entrantsData.currentTypes);

      this.selectedData = cloneDeep(this.entrantsData.selectedData);
      this.constraints = this.constraintsData;
    },
    updateConstraints(val, constraint) {
      if (val) {
        if (!this.constraints.includes(constraint)) {
          this.constraints.push(constraint);
        }
      } else {
        this.constraints = this.constraints.filter(item => item !== constraint);
      }
      this.$emit('updateConstraints', this.constraints);
    },
    // OPT IN SWITCH
    swipeOptInHandler(direction) {
      this.optinRequiredForEntrants = direction === 'right';
    },
    optInUpdate(val) {
      this.optinRequiredForEntrants = val;
    },
    // swipe limit Participants
    swipelimitParticipantsHandler(direction) {
      this.limitParticipants = direction === 'right';
    },
    limitParticipantsInUpdate(val) {
      this.limitParticipants = val;
    },
    //
    swipeCanParticipateHandler(direction) {
      this.canParticipate = direction === 'right';
    },
    canParticipateUpdate(val) {
      this.entrantsData.canParticipate = val;
      this.canParticipate = val;
    },
    // Update Limit
    handleUpdateLimit(val) {
      const [min, max] = val;

      this.formData.minNumberOfEntrants = min;
      this.formData.maxNumberOfEntrants = max;
      this.$emit('updateEntrantsData', {
        ...this.entrantsData,
        formData: this.formData,
        selectedData: this.selectedData
      })
    },
    toSelectTable() {
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isSelectTableVisible = false;
    },
    resetCanParticipate(val) {
      if (val) {
        const dependantFormData = {
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        }
        this.formData = {...this.formData, ...dependantFormData};

        this.$emit('updateEntrantsData', {
          ...this.entrantsData,
          formData: this.formData,
          selectedData: []
        })
      }
    },
    tagsSelectData({selectedData, formData}) {
      selectedData.map((item) => {
        item.valid = formData.mustNot.includes(item.id) || formData.must.includes(item.id) || formData.should.includes(item.id);
        return item;
      })
      this.selectedData = selectedData;

      this.formData = formData;
      this.$emit('updateEntrantsData', {...this.entrantsData, formData, selectedData});
    },
    handleUpdateEntrantsData(val) {
      this.selectedData = val.selectedData;
      this.formData = val.formData;
      this.$emit('updateEntrantsData', {...this.entrantsData, formData: val.formData, selectedData: val.selectedData});
    },
    updateCurrentTypes(val) {
      this.currentTypes = val;
      this.$emit('updateCurrentTypes', val)
    },
    resetEntrantsValidation() {
      this.$emit('resetEntrantsValidation');
    },
    numberOfEntrantsBlur() {
      if (
        this.formData.maxNumberOfEntrants
        && Number(this.formData.maxNumberOfEntrants) < Number(this.formData.minNumberOfEntrants)
      ) {
        this.formData.maxNumberOfEntrants = this.formData.minNumberOfEntrants;
      }
      this.$emit('updateEntrantsData', {
        ...this.entrantsData,
        formData: this.formData,
        selectedData: this.selectedData
      })
    }
  },
  watch: {
    optinRequiredForEntrants(val) {
      this.updateConstraints(val, 'optinRequiredForEntrants');
      this.$emit('updateOptIn', val)
    },
    limitParticipants(val) {
      if (val) {
        this.formData.maxNumberOfEntrants = 40;
      } else {
        this.formData.maxNumberOfEntrants = null;
        this.formData.minNumberOfEntrants = null;
      }
      this.$emit('updateLimitParticipants', val)
    },
    canParticipate(val) {
      this.resetCanParticipate(val);
      this.$emit('updateCanParticipate', val)
    },
  },
  destroyed() {
    this.$emit('resetEntrantsValidation');
  }
}
</script>
